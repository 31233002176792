import {
  SET_ADD_PROPERTY,
  SET_EDIT_PROPERTY,
  SET_GET_ALL_LEADS,
  SET_GET_ALL_PROEPRTIES,
  SET_SEARCH_IN_ALL_LEADS,
  SET_SEARCH_IN_ALL_PROPERTIES,
  SET_ADD_FAQS,
  SET_EDIT_FAQS,
  SET_DELETE_FAQS,
  SET_IS_FEATURED_PROPERTY,
  SET_UPDATE_PROPERTY_STATUS,
  SET_DASHBOARD,
  SET_GET_FAQS
} from '../actions/types';

const initialState = {
  addProperty: false,
  editProperty: false,
  allLeads: [],
  allProperties: [],
  addFaqs: false,
  editFaqs: false,
  deleteFaqs: false,
  isFeaturedProperty: false,
  updatePropertyStatus: false,
  Dashboard: [],
  faqs: [],
}


export default function adminReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_ADD_PROPERTY:
      return {
        ...state,
        addProperty: true
      };
    case SET_EDIT_PROPERTY:
      return {
        ...state,
        editProperty: true
      };
    case SET_GET_ALL_LEADS:
      return {
        ...state,
        allLeads: payload
      };
    case SET_GET_ALL_PROEPRTIES:
      return {
        ...state,
        allProperties: payload
      };
    case SET_SEARCH_IN_ALL_LEADS:
      return {
        ...state,
        allLeads: payload
      };
    case SET_SEARCH_IN_ALL_PROPERTIES:
      return {
        ...state,
        allProperties: payload
      };
    case SET_ADD_FAQS:
      return {
        ...state,
        addFaqs: true
      };
    case SET_EDIT_FAQS:
      return {
        ...state,
        editFaqs: true
      };
    case SET_DELETE_FAQS:
      return {
        ...state,
        deleteFaqs: true
      };
    case SET_IS_FEATURED_PROPERTY:
      return {
        ...state,
        isFeaturedProperty: true
      };
    case SET_UPDATE_PROPERTY_STATUS:
      return {
        ...state,
        updatePropertyStatus: true
      };
    case SET_DASHBOARD:
      return {
        ...state,
        Dashboard: payload
      };
    case SET_GET_FAQS:
      return {
        ...state,
        faqs: payload
      };
    default:
      return state
  }
};