import { combineReducers } from 'redux';
import websiteReducer from './websiteReducer';
import adminReducer from './adminReducer';
import authReducer from './authReducer';

export default combineReducers({
  website: websiteReducer,
  admin: adminReducer,
  auth: authReducer,
});
