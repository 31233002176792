import {
  SET_GET_FEATURED_PROPERTIES,
  SET_GET_REAL_ESTATE_BY_REGIONS,
  SET_CONTACT_US,
  SET_SEARCH_REGION,
  SET_GET_ALL_REGION_NAMES,
  SET_GET_PROPERTIES_BY_REGION,
  SET_SEARCH_PORPERTY,
  SET_INVEST_WITH_US,
  SET_CONTACT_SELLER,
  SET_FILTER_PROPERTIES,
  SET_SELL_PROPERTY,
  SET_GET_FAQS,
  SET_SEARCH_IN_FAQS,
  PAGE_VISITORS,
  SET_GET_PROJECT_NAMES,
  SET_GET_PROPERTIES_BY_PROJECT_NAME,

} from '../actions/types';

const initialState = {
  featuredProperties: [],
  realEstateByRegions: [],
  contactUs: false,
  allRegionNames: [],
  allPropertiesByRegion: {},
  investWithUs: false,
  contactSeller: false,
  filterProperties: [],
  sellProperty: false,
  faqs: [],
  pageVisitors: [],
  projectNames: [],

};

export default function websiteReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_GET_FEATURED_PROPERTIES:
      return {
        ...state,
        featuredProperties: payload
      };
    case SET_GET_REAL_ESTATE_BY_REGIONS:
      return {
        ...state,
        realEstateByRegions: payload
      };
    case SET_CONTACT_US:
      return {
        ...state,
        contactUs: true
      };
    case SET_SEARCH_REGION:
      return {
        ...state,
        realEstateByRegions: payload
      };
    case SET_GET_ALL_REGION_NAMES:
      return {
        ...state,
        allRegionNames: payload
      };
    case SET_GET_PROPERTIES_BY_REGION:
      return {
        ...state,
        allPropertiesByRegion: payload
      };
    case SET_SEARCH_PORPERTY:
      return {
        ...state,
        allPropertiesByRegion: payload
      };
    case SET_INVEST_WITH_US:
      return {
        ...state,
        investWithUs: true
      };
    case SET_CONTACT_SELLER:
      return {
        ...state,
        contactSeller: true
      };
    case SET_FILTER_PROPERTIES:
      return {
        ...state,
        allPropertiesByRegion: payload
      };
    case SET_SELL_PROPERTY:
      return {
        ...state,
        sellProperty: true
      };
    case SET_GET_FAQS:
      return {
        ...state,
        faqs: payload
      };
    case SET_SEARCH_IN_FAQS:
      return {
        ...state,
        faqs: payload
      };
    case PAGE_VISITORS:
      return {
        ...state,
        pageVisitors: payload
      };
    case SET_GET_PROJECT_NAMES:
      return {
        ...state,
        projectNames: payload
      };
      case SET_GET_PROPERTIES_BY_PROJECT_NAME:
      return {
        ...state,
        allPropertiesByRegion: payload
      };
    default:
      return state
  }
};