import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './App.css';
import pageNotFound from './assests/icons/page_not_found.svg';


const Homepage = lazy(() => import('./components/website/Homepage'));
const AboutUs = lazy(() => import('./components/website/AboutUs'));
const InvestWithUs = lazy(() => import('./components/website/InvestWithUs'));
const FAQS = lazy(() => import("./components/website/FAQ's"));
const ContactUs = lazy(() => import("./components/website/ContactUs"));
const Properties = lazy(() => import("./components/website/Properties"));
const Region = lazy(() => import("./components/website/Region"));
const Property = lazy(() => import("./components/website/Property"));


/*Admin Routes */
const Admin = lazy(() => import('./components/admin'));
const Login = lazy(() => import("./components/admin/Login"));


const NotFound = () => {
  return(
    <div style={{textAlign: "center", paddingTop: "100px"}}>
      <img src={pageNotFound} alt=""  /><br/><br/>
      <div style={{font: "normal normal 18px latoBold"}}>
        <h1>Error 404</h1>
        <h2>Oops! Page Not Found</h2>
      </div><br/>
      <Link to="/"><Button >Go Back</Button></Link>
    </div>
  )
}
// const NotFound = _ =>
//   <Result
//     status="404"
//     title="404"
//     subTitle="Sorry, the page you visited does not exist."
//     extra={<Link to="/"><Button type="primary">Back Home</Button></Link>}
//   />;

const AppRoutes = _ => (
  <Suspense fallback={<LoadingOutlined style={{ fontSize: 100, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "20%" }} spin />}>
    <Router>
      <Switch>

        {/* Website Routes */}
        <Route exact path='/' component={Homepage} />
        <Route exact path='/about-us' component={AboutUs} />
        <Route exact path='/properties' component={Properties} />
        <Route exact path='/invest-with-us' component={InvestWithUs} />
        <Route exact path='/faqs' component={FAQS} />
        <Route exact path='/contact-us' component={ContactUs} />
        <Route exact path='/region' component={Region} />
        <Route exact path='/property/:id' component={Property} />

        {/* Admin Routes */}
        <Route path='/admin' component={localStorage.getItem('token') ? Admin : NotFound} />
        <Route path='/login' component={Login} />

        {/* Not Found Route */}
        <Route component={NotFound} />

      </Switch>
    </Router>
  </Suspense>
);

export default AppRoutes;
