import {
  SET_LOGIN
} from '../actions/types';

const initialState = {
  isAuthenticated: false,
  user: {}
};

export default function authReducer (
  state = initialState, {
    type,
    payload
  }) {
  switch (type) {
    case SET_LOGIN:
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
};
