import React from 'react';
import Routes from './routes';
import { Provider } from 'react-redux';
import configureStore from './store';
import './App.css';
import 'antd/dist/antd.css';

const App = _ => {
  const store = configureStore();
  return (
    <div>
      <Provider store={store}>
        <Routes />
      </Provider>
    </div>
  );
};

export default App;