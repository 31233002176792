// user types
export const SET_GET_FEATURED_PROPERTIES = 'SET_GET_FEATURED_PROPERTIES';
export const SET_GET_REAL_ESTATE_BY_REGIONS = 'SET_GET_REAL_ESTATE_BY_REGIONS';
export const SET_CONTACT_US = 'SET_CONTACT_US';
export const SET_SEARCH_REGION = 'SET_SEARCH_REGION';
export const SET_GET_ALL_REGION_NAMES = 'SET_GET_ALL_REGION_NAMES';
export const SET_GET_PROPERTIES_BY_REGION = 'SET_GET_PROPERTIES_BY_REGION';
export const SET_SEARCH_PORPERTY = 'SET_SEARCH_PORPERTY';
export const SET_INVEST_WITH_US = 'SET_INVEST_WITH_US';
export const SET_CONTACT_SELLER = 'SET_CONTACT_SELLER';
export const SET_FILTER_PROPERTIES = 'SET_FILTER_PROPERTIES';
export const SET_SELL_PROPERTY = 'SET_SELL_PROPERTY';
export const SET_GET_FAQS = 'SET_GET_FAQS';
export const SET_SEARCH_IN_FAQS = 'SET_SEARCH_IN_FAQS';
export const PAGE_VISITORS = 'PAGE_VISITORS';
export const SET_GET_PROJECT_NAMES = 'SET_GET_PROJECT_NAMES';
export const SET_GET_PROPERTIES_BY_PROJECT_NAME = 'SET_GET_PROPERTIES_BY_PROJECT_NAME';


// admin types
export const SET_ADD_PROPERTY = 'SET_ADD_PROPERTY';
export const SET_EDIT_PROPERTY = 'SET_EDIT_PROPERTY';
export const SET_GET_ALL_LEADS = 'SET_GET_ALL_LEADS';
export const SET_GET_ALL_PROEPRTIES = 'SET_GET_ALL_PROEPRTIES';
export const SET_SEARCH_IN_ALL_PROPERTIES = 'SET_SEARCH_IN_ALL_PROPERTIES';
export const SET_SEARCH_IN_ALL_LEADS = 'SET_SEARCH_IN_ALL_LEADS';
export const SET_ADD_FAQS = 'SET_ADD_FAQS';
export const SET_IS_FEATURED_PROPERTY = 'SET_IS_FEATURED_PROPERTY';
export const SET_UPDATE_PROPERTY_STATUS = 'SET_UPDATE_PROPERTY_STATUS';
export const SET_DASHBOARD = 'SET_DASHBOARD';
export const SET_EDIT_FAQS = 'SET_EDIT_FAQS';
export const SET_DELETE_FAQS = 'SET_DELETE_FAQS';



//auth types
export const SET_LOGIN = ' SET_LOGIN';


//error types
export const SET_ERRORS = 'SET_ERRORS';
export const RESET_ERRORS = 'RESET_ERRORS';